import Arbitrum from "../assets/icons/arbitrum.svg"
import PolygonMumbai from "../assets/icons/header/polygonMumbai.svg"
import Goerli from "../assets/icons/header/ethereum.svg"
import Binance from "../assets/icons/header/binance.svg"
import Optimism from "../assets/icons/header/optimism.svg"
import Zksync from "../assets/icons/header/zksync.svg"
import Arthera from "../assets/icons/header/arthera.svg"
import Scroll from "../assets/icons/header/scroll.svg"
import Zetachain from "../assets/icons/header/zetachain.svg"
import Linea from "../assets/icons/header/linea.svg"

export const chainIcons = {
    "Arbitrum One": Arbitrum,
    "Ethereum": Goerli,
    "BNB Smart Chain": Binance,
    "Polygon": PolygonMumbai,
    "OP Mainnet": Optimism,
    "zkSync Era": Zksync,
    "Scroll": Scroll,
    "Linea": Linea,
    "Chain 10243": Arthera,
    "Chain 7001": Zetachain,
    "Chain 534351": Scroll,
    "Chain 59140": Linea,

    "Arbitrum Goerli": Arbitrum,
    "Polygon Mumbai": PolygonMumbai,
    "Goerli": Goerli,
    "Binance Smart Chain Testnet": Binance,
    "Optimism Goerli": Optimism,
};
